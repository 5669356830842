var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkForButtonAndIndex
    ? _c(
        "div",
        { staticClass: "userFollowItem" },
        [
          _c(
            "router-link",
            {
              staticClass: "img",
              attrs: { to: { path: "profile", query: { id: _vm.user.id } } },
              nativeOn: {
                click: function ($event) {
                  return _vm.setProfileViewUser.apply(null, arguments)
                },
              },
            },
            [
              _c("UserImageThumbnail", {
                attrs: { thumbnailAssetId: _vm.user.thumbnailAssetId },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "middleCopy" }, [
            _c(
              "div",
              { staticClass: "name" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { path: "profile", query: { id: _vm.user.id } },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.setProfileViewUser.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.user.namePreferred) + "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.position))]),
          ]),
          _vm.showBtn
            ? _c("div", { staticClass: "button", on: { click: _vm.follow } }, [
                _c(
                  "div",
                  { staticClass: "plusIcon" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: ["fas", "user-plus"], size: "2x" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }